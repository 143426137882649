const DetailsView = () =>
  import(
    /* webpackChunkName: "details" */ '@/modules/details/views/DetailsView'
  )
const CreateDetails = () => import('@/modules/details/views/CreateDetails')
const UpdateDetails = () => import('@/modules/details/views/UpdateDetails')

export default [
  {
    path: '/detalles',
    name: 'details',
    meta: {
      title: 'Detalles',
      rule: 'manager',
    },
    component: DetailsView,
  },
  {
    path: '/nuevo-detalle',
    name: 'newDetails',
    meta: {
      title: 'Crear detalle',
      rule: 'manager',
    },
    component: CreateDetails,
  },
  {
    path: '/editar-detalle',
    name: 'updateDetails',
    meta: {
      title: 'Editar detalle',
      rule: 'manager',
    },
    component: UpdateDetails,
  },
]
