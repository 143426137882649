<template>
  <vs-navbar id="main-navbar" class="app-nav" color="dark">
    <div slot="title" vs-w="12">
      <vs-navbar-title>
        <vs-row vs-justify="center">
          <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <vs-button
              color="dark"
              type="filled"
              icon="menu"
              @click="showSideBar"
            >
              <span class="page-title" v-text="pageTitle" />
            </vs-button>
          </vs-col>
        </vs-row>
      </vs-navbar-title>
    </div>
  </vs-navbar>
</template>

<script>
import { eventBus } from '@/plugins/event-bus'
import { mapState } from 'vuex'

export default {
  name: 'NavBar',
  data: function () {
    return {
      activeItem: 0,
    }
  },
  computed: {
    ...mapState('auth', { token: 'tokenAccess' }),
    pageTitle: function () {
      return this.$route.meta.title
    },
  },
  beforeDestroy() {
    eventBus.$off('showSideBar', this.listener)
  },
  methods: {
    showSideBar: () => {
      eventBus.$emit('showSideBar')
    },
  },
}
</script>

<style lang="scss">
@import '~@/scss/vuexy/_variables.scss';
#main-navbar {
  color: rgb(255, 255, 255);
  height: $navbar-height;
  top: 0;
  left: 0;
  .page-title {
    margin-left: 1rem;
    font-size: 1.2rem;
  }
  .vs-navbar--btn-responsive {
    display: none;
  }
  a.link {
    color: #fefefe;
    font-weight: 300;
    transition: all 0.3s ease;
    &:hover {
      color: #cdcdcd;
    }
  }
}
</style>
