import axios from 'axios'

/**
 * Envía la petición al servidor para el envío de la invitación de nuevo usuario.
 * @param data Object Objeto con la información del paciente
 * @returns {Promise<Object>}
 */
export async function inviteUserAction({ commit }, data) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Enviando email de invitación',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/invitation/', data)
      .then((response) => {
        resolve(response !== undefined)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Obtiene los detalles de la empresa.
 *
 * @param companyId Identificador de la empresa.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getCompany({ commit }, companyId) {
  return new Promise((resolve, reject) => {
    let url = `/companies/${companyId}/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Actualiza la información de la empresa.
 *
 */
export async function updateCompany(
  { commit },
  { data, companyId, close_loading = true }
) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando datos de la empresa',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/companies/${companyId}/`
    axios
      .patch(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        if (close_loading === true) {
          commit('setLoading', false, { root: true })
        }
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getCompanyCatalog({ commit }) {
  return new Promise((resolve, reject) => {
    let url = `/companies/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
