const MyProfileView = () =>
  import(/* webpackChunkName: "users" */ '@/modules/users/views/MyProfileView')

export default [
  {
    path: '/perfil',
    name: 'MyProfile',
    meta: {
      title: 'Mi perfil',
      rule: 'manager',
    },
    component: MyProfileView,
  },
]
