const _ = require('lodash')
export default {
  //Asigna los valores de la tabla
  setDataTableDetail: (state, dataTableDetail) => {
    state.dataTableDetail = dataTableDetail
  },
  //Asigna los valores de las operaciones activas
  setDataTableOperationActive: (state, dataTableOperationActive) => {
    state.dataTableOperationActive = dataTableOperationActive
  },
  //Lista de operaciones seleccionadas
  setDataSearchOperationListActive: (state, dataSearchOperationListActive) => {
    state.dataSearchOperationListActive = dataSearchOperationListActive
  },
  //Asigna los valores al objeto cuando se filtran
  setFilterDataOperationActive: (state, filterDataOperationActive) => {
    state.filterDataOperationActive = _.cloneDeep(filterDataOperationActive)
  },
  //SAM total
  setTotalSamDetail: (state, totalSamOperationsActive) => {
    state.totalSamDetail = _.cloneDeep(totalSamOperationsActive)
  },
  //Asigna los valores del nuevo detalle
  setNewDataDetail: (state, newDataDetail) => {
    state.newDataDetail = newDataDetail
  },
  //Asigna el id y la secuencia de cada operación para cada detalle
  setOperationListWithSequence: (state, operationListWithSequence) => {
    state.operationListWithSequence = operationListWithSequence
  },
  //Asigna el detalle a editar
  setDetailDataToEdit: (state, detailDataToEdit) => {
    state.detailDataToEdit = detailDataToEdit
  },
  setEnableUpdatedetail: (state, enableUpdatedetail) => {
    state.enableUpdatedetail = _.cloneDeep(enableUpdatedetail)
  },
  setUpdateDataDetail: (state, updateDataDetail) => {
    state.updateDataDetail = updateDataDetail
  },
  setOperationSequence: (state, operationSequence) => {
    state.operationSequence = operationSequence
  },
}
