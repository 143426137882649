export default {
  basesList: [],
  editBase: {},
  detailsListActive: [], //Detalles activos
  searchDetailActive: false, //Popup detalles
  updateListSequenceDetails: false, //bandera para agregar detalles a la lista
  operationsDetail: [], //operaciones de los detalles
  basesReference: [], //bases para los modelos
  selectBases: [],
  selectedBase: null,
}
