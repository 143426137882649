export default {
  setBasesList: (state, basesList) => {
    let data = []
    basesList.map((base) => {
      data.push({
        id: base.id,
        name: base.name,
        description: base.description,
        sam: base.sam.toFixed(2),
        image: base.image,
      })
    })
    state.basesList = data
  },
  setEditBase: (state, editBase) => {
    state.editBase = editBase
  },
  //Asigna los valores de la tabla
  setDetailsListActive: (state, detailsListActive) => {
    state.detailsListActive = detailsListActive
  },
  //Abre / cierra popup
  setSearchDetailActive: (state, searchDetailActive) => {
    state.searchDetailActive = searchDetailActive
  },
  setUpdateListSequenceDetails: (state, updateListSequenceDetails) => {
    state.updateListSequenceDetails = updateListSequenceDetails
  },
  setOperationsDetail: (state, operationsDetail) => {
    state.operationsDetail = operationsDetail
  },
}
