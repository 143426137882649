import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'

// Vue Router
import router from './router'

// Vuex Store
import store from './store'
import './filters/filters'

// Styles: SCSS
import './scss/main.scss'
import './assets/css/iconfont.css'

// Theme Configurations
import '../themeConfig.js'

// Globally Registered Components
import './globalComponents.js'
// Tailwind
import './assets/css/main.css'
// acl
import acl from './acl'

require('./plugins')

Vue.config.productionTip = false

new Vue({
  router,
  acl,
  store,
  render: (h) => h(App),
}).$mount('#app')
