export default {
  dataTableDetail: [], //Datos de la tabla
  dataSearchOperationListActive: [], //Operaciones para drag and drop
  filterDataOperationActive: {}, //Elemntos del formulario de búsqueda
  totalSamDetail: 0, //SAM total
  newDataDetail: {}, //Datos del detalle a crear
  operationListWithSequence: [], //ID y secuencia de las operaciones
  detailDataToEdit: {}, //Obtiene los datos del detalle a modificar
  operationActiveOfDetail: [], //Obtiene las operaciones y secuencias para buscar operaciones
  enableUpdatedetail: false,
  updateDataDetail: {},
  operationSequence: [],
}
