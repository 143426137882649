const CompanyStaffView = () =>
  import(
    /* webpackChunkName: "companies" */ '@/modules/companies/views/CompanyStaffView'
  )

export default [
  {
    path: '/tienda/usuarios',
    name: 'CompanyStaff',
    meta: {
      title: 'Administración de usuarios',
      rule: 'manager',
    },
    component: CompanyStaffView,
  },
]
