import Vue from 'vue'
import VueRouter from 'vue-router'
import authRoutes from '@/modules/auth/router'
import catalogsRoutes from '@/modules/catalogs/router'
import companyRoutes from '@/modules/companies/router'
import usersRoutes from '@/modules/users/router'
import detailsRoutes from '@/modules/details/router'
import operationsRoutes from '@/modules/operations/router'
import basesRoutes from '@/modules/bases/router'
import patternsRoutes from '@/modules/patterns/router'
import store from '@/store'

Vue.use(VueRouter)

let baseRoutes = [
  {
    path: '*',
    name: 'index',
    redirect: {
      name: 'authLogin',
    },
  },
]

// noinspection JSCheckFunctionSignatures
const routes = baseRoutes.concat(
  authRoutes,
  catalogsRoutes,
  companyRoutes,
  usersRoutes,
  operationsRoutes,
  detailsRoutes,
  basesRoutes,
  patternsRoutes
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const requiresCompanyAdmin = to.matched.some(
    (record) => record.meta.requiresCompanyAdmin
  )
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin)
  const isLogged = store.getters['auth/isLogged']
  const isManagerOrAdmin = store.getters['auth/isManagerOrAdmin']
  const isAdmin = store.getters['auth/isAdmin']
  if (isLogged && to.path === '/auth/login') {
    next({ name: 'productList' })
  }

  if (isLogged && to.path === '/auth/signup') {
    next({ name: 'productList' })
  }

  // validate the views that can only be visited by managers
  if (requiresCompanyAdmin && !isManagerOrAdmin) {
    next({ name: 'MyProfile' })
  }

  if (requiresAuth && !isLogged) {
    next({ name: 'authLogin' })
  }

  // validate the views that can only be visited by admins
  if (requiresAdmin && !isAdmin) {
    next({ name: 'MyProfile' })
  }

  next()
})

router.afterEach(() => {
  const Stickedtooltips = document.querySelectorAll('.vs-tooltip')
  for (const tooltip of Stickedtooltips) {
    tooltip.remove()
  }
})
export default router
