const PatternsView = () => import('@/modules/patterns/views/PatternsView')
const CreatePattern = () => import('@/modules/patterns/views/CreatePatterns')
const UpdatePattern = () => import('@/modules/patterns/views/UpdatePatterns')

export default [
  {
    path: '/modelos',
    name: 'patterns',
    meta: {
      title: 'Modelos',
      rule: 'manager',
    },
    component: PatternsView,
  },
  {
    path: '/nuevo-modelo',
    name: 'newPatterns',
    meta: {
      title: 'Crear modelo',
      rule: 'manager',
    },
    component: CreatePattern,
  },
  {
    path: '/editar-modelo',
    name: 'updatePattern',
    meta: {
      title: 'Editar modelo',
      rule: 'manager',
    },
    component: UpdatePattern,
  },
]
