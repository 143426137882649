const OperationsView = () =>
  import(
    /* webpackChunkName: "operations" */ '@/modules/operations/views/OperationsView'
  )

export default [
  {
    path: '/operaciones',
    name: 'operations',
    meta: {
      title: 'Operaciones',
      rule: 'manager',
    },
    component: OperationsView,
  },
]
