<template>
  <div class="container">
    <vs-sidebar
      v-model="active"
      :default-index="getDefaultIndex()"
      color="primary"
      parent="body"
      class="sidebarx"
      :click-not-close="true"
      spacer
    >
      <div slot="header" class="header-sidebar">
        <div class="header-desc">
          <vs-button
            icon="close"
            color="primary"
            type="flat"
            @click="active = false"
          />
        </div>
        <template v-if="user.userprofile.picture !== '#'">
          <vs-avatar size="70px" :src="user.userprofile.picture" />
        </template>
        <vs-avatar
          v-else
          size="70px"
          :src="require('@/assets/images/user-avatar.png')"
        />

        <h4 class="small-font">
          <span class="long-text">
            {{ user.name }} {{ user.lastName }} {{ user.secondLastName }}</span
          >
        </h4>
        <h4>{{ user.userprofile.role | capitalize }}</h4>
        <span class="small-font long-text">{{ user.email }}</span>
        <h4 class="mt-1">
          <span class="long-text">{{ user.userprofile.company.name }}</span>
        </h4>
      </div>
      <!-- Mi Perfil -->
      <vs-sidebar-group title="Mi Perfil" :open="true">
        <div @click="selectOption('Ver mi perfil')">
          <router-link
            class="side-bar-router mb-1"
            :class="{ 'active-route': select === 'Ver mi perfil' }"
            :to="{
              name: 'MyProfile',
            }"
          >
            <span class="material-icons mr-1 font-s-1"> account_box </span>
            Ver mi perfil
          </router-link>
        </div>
      </vs-sidebar-group>

      <vs-divider
        v-if="
          !(
            user.userprofile.role === roles.ADMIN ||
            user.userprofile.role === roles.MANAGER
          )
        "
        position="left"
      />

      <!-- Mi empresa -->
      <vs-sidebar-group
        v-if="
          user.userprofile.role === roles.ADMIN ||
          user.userprofile.role === roles.MANAGER
        "
        title="Mi Empresa"
        :open="true"
      >
        <!-- Modelos -->
        <div @click="selectOption('modelos')">
          <router-link
            class="side-bar-router mb-1"
            :class="{ 'active-route': select === 'modelos' }"
            :to="{
              name: 'patterns',
            }"
          >
            <span class="material-icons mr-1 font-s-1">
              settings_accessibility
            </span>
            Modelos
          </router-link>
        </div>
        <!-- Bases -->
        <div @click="selectOption('base')">
          <router-link
            class="side-bar-router mb-1"
            :class="{ 'active-route': select === 'base' }"
            :to="{
              name: 'bases',
            }"
          >
            <span class="material-icons mr-1 font-s-1"> menu_book </span>
            Bases
          </router-link>
        </div>
        <!-- Detalles -->
        <div @click="selectOption('detail')">
          <router-link
            class="side-bar-router mb-1"
            :class="{ 'active-route': select === 'detail' }"
            :to="{
              name: 'details',
            }"
          >
            <span class="material-icons mr-1 font-s-1"> visibility </span>
            Detalles
          </router-link>
        </div>
        <!-- Operaciones -->
        <div @click="selectOption('operacion')">
          <router-link
            class="side-bar-router mb-1"
            :class="{ 'active-route': select === 'operacion' }"
            :to="{
              name: 'operations',
            }"
          >
            <span class="material-icons mr-1 font-s-1"> settings </span>
            Operaciones
          </router-link>
        </div>
        <!-- Catálogos -->
        <div @click="selectOption('catalogo')">
          <router-link
            class="side-bar-router mb-1"
            :class="{ 'active-route': select === 'catalogo' }"
            :to="{
              name: 'catalogs',
            }"
          >
            <span class="material-icons mr-1 font-s-1"> library_books </span>
            Catálogos
          </router-link>
        </div>
        <!-- Usuarios -->
        <div
          v-if="
            user.userprofile.role === roles.ADMIN ||
            user.userprofile.role === roles.MANAGER
          "
          @click="selectOption('Usuarios')"
        >
          <router-link
            class="side-bar-router mb-1"
            :class="{ 'active-route': select === 'Usuarios' }"
            :to="{
              name: 'CompanyStaff',
            }"
          >
            <span class="material-icons mr-1 font-s-1">
              supervised_user_circle
            </span>
            Usuarios
          </router-link>
        </div>
      </vs-sidebar-group>
      <!-- Footer -->
      <div slot="footer" class="footer-sidebar">
        <vs-button icon="reply" color="danger" type="flat" @click="_logout">
          Cerrar Sesión
        </vs-button>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

import { eventBus } from '@/plugins/event-bus'
import utils from '@/utils/utils'

export default {
  name: 'MainSideBar',
  data: function () {
    return {
      active: false,
      select: '',
      roles: utils.roles,
    }
  },
  computed: {
    ...mapState('auth', {
      user: 'user',
    }),
    ...mapState('companies', {
      company: 'company',
    }),
    ...mapGetters('auth', {
      isManagerOrAdmin: 'isManagerOrAdmin',
    }),
  },
  created() {
    eventBus.$on('showSideBar', this.showSideBar)
  },
  methods: {
    ...mapActions('auth', { logout: 'logout' }),
    showSideBar() {
      this.active = true
    },
    selectOption(txt) {
      this.active = false
      this.select = txt
    },
    getDefaultIndex() {
      if (this.isManagerOrAdmin) {
        return '3.1'
      }
      return '1.3'
    },
    _logout() {
      let self = this
      this.hideSideBar()
      setTimeout(function () {
        self.logout()
        self.$router.push({ name: 'authLogin' })
      }, 300)
    },
    hideSideBar() {
      this.active = false
    },
  },
}
</script>

<style scoped lang="scss">
.side-bar-router {
  padding: 10px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  display: block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: inherit;
  opacity: 0.8;
  font-size: 0.85rem;
  text-decoration: none;
  &:hover {
    color: rgba(var(--vs-primary), 1) !important;
    opacity: 1 !important;
  }
}

.active-route {
  color: rgba(var(--vs-primary), 1) !important;
  opacity: 1 !important;
  font-weight: bold;
}

.long-text {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .header-desc {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    > button {
      margin-left: 10px;
    }
  }
}

.footer-sidebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > button {
    border: 0px solid rgba(0, 0, 0, 0) !important;
    border-left: 1px solid rgba(0, 0, 0, 0.07) !important;
    border-radius: 0px !important;
  }
}
.font-s-1 {
  font-size: 1rem;
}
</style>
