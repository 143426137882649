import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/modules/auth/store'
import companies from '@/modules/companies/store'
import bases from '@/modules/bases/store'
import patterns from '@/modules/patterns/store'
import catalogs from '@/modules/catalogs/store'
import details from '@/modules/details/store'
import operations from '@/modules/operations/store'
import users from '@/modules/users/store'

import VuexPersistence from 'vuex-persist'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth'],
})
export default new Vuex.Store({
  state: {
    activeLoading: false,
    loadingPageMsg: '',
    showNavbar: true,
    errorMsg: '',
    skeletonLoading: true,
  },
  mutations: {
    setLoading(state, { isLoading, msg }) {
      state.activeLoading = isLoading
      state.loadingPageMsg = msg
    },
    setErrorMsg(state, errorMsg) {
      state.errorMsg = errorMsg
    },
    setSkeletonLoading: (state, isLoading) => {
      state.skeletonLoading = isLoading
    },
    setShowNavbar(state, showNavbar) {
      state.showNavbar = showNavbar
    },
  },
  modules: {
    auth,
    companies,
    bases,
    catalogs,
    details,
    operations,
    users,
    patterns,
  },
  plugins: [vuexLocal.plugin],
})
