var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('vs-sidebar',{staticClass:"sidebarx",attrs:{"default-index":_vm.getDefaultIndex(),"color":"primary","parent":"body","click-not-close":true,"spacer":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('div',{staticClass:"header-sidebar",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"header-desc"},[_c('vs-button',{attrs:{"icon":"close","color":"primary","type":"flat"},on:{"click":function($event){_vm.active = false}}})],1),(_vm.user.userprofile.picture !== '#')?[_c('vs-avatar',{attrs:{"size":"70px","src":_vm.user.userprofile.picture}})]:_c('vs-avatar',{attrs:{"size":"70px","src":require('@/assets/images/user-avatar.png')}}),_c('h4',{staticClass:"small-font"},[_c('span',{staticClass:"long-text"},[_vm._v(" "+_vm._s(_vm.user.name)+" "+_vm._s(_vm.user.lastName)+" "+_vm._s(_vm.user.secondLastName))])]),_c('h4',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.user.userprofile.role)))]),_c('span',{staticClass:"small-font long-text"},[_vm._v(_vm._s(_vm.user.email))]),_c('h4',{staticClass:"mt-1"},[_c('span',{staticClass:"long-text"},[_vm._v(_vm._s(_vm.user.userprofile.company.name))])])],2),_c('vs-sidebar-group',{attrs:{"title":"Mi Perfil","open":true}},[_c('div',{on:{"click":function($event){return _vm.selectOption('Ver mi perfil')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Ver mi perfil' },attrs:{"to":{
            name: 'MyProfile',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" account_box ")]),_vm._v(" Ver mi perfil ")])],1)]),(
        !(
          _vm.user.userprofile.role === _vm.roles.ADMIN ||
          _vm.user.userprofile.role === _vm.roles.MANAGER
        )
      )?_c('vs-divider',{attrs:{"position":"left"}}):_vm._e(),(
        _vm.user.userprofile.role === _vm.roles.ADMIN ||
        _vm.user.userprofile.role === _vm.roles.MANAGER
      )?_c('vs-sidebar-group',{attrs:{"title":"Mi Empresa","open":true}},[_c('div',{on:{"click":function($event){return _vm.selectOption('modelos')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'modelos' },attrs:{"to":{
            name: 'patterns',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" settings_accessibility ")]),_vm._v(" Modelos ")])],1),_c('div',{on:{"click":function($event){return _vm.selectOption('base')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'base' },attrs:{"to":{
            name: 'bases',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" menu_book ")]),_vm._v(" Bases ")])],1),_c('div',{on:{"click":function($event){return _vm.selectOption('detail')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'detail' },attrs:{"to":{
            name: 'details',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" visibility ")]),_vm._v(" Detalles ")])],1),_c('div',{on:{"click":function($event){return _vm.selectOption('operacion')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'operacion' },attrs:{"to":{
            name: 'operations',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" settings ")]),_vm._v(" Operaciones ")])],1),_c('div',{on:{"click":function($event){return _vm.selectOption('catalogo')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'catalogo' },attrs:{"to":{
            name: 'catalogs',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" library_books ")]),_vm._v(" Catálogos ")])],1),(
          _vm.user.userprofile.role === _vm.roles.ADMIN ||
          _vm.user.userprofile.role === _vm.roles.MANAGER
        )?_c('div',{on:{"click":function($event){return _vm.selectOption('Usuarios')}}},[_c('router-link',{staticClass:"side-bar-router mb-1",class:{ 'active-route': _vm.select === 'Usuarios' },attrs:{"to":{
            name: 'CompanyStaff',
          }}},[_c('span',{staticClass:"material-icons mr-1 font-s-1"},[_vm._v(" supervised_user_circle ")]),_vm._v(" Usuarios ")])],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"footer-sidebar",attrs:{"slot":"footer"},slot:"footer"},[_c('vs-button',{attrs:{"icon":"reply","color":"danger","type":"flat"},on:{"click":_vm._logout}},[_vm._v(" Cerrar Sesión ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }