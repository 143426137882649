const _ = require('lodash')
export default {
  //Modifica el título eliminar, editar, nueva operacion
  updateTitleCard: (state, title) => {
    state.titleCard = title
  },
  //Muestra u oculta el formulario
  updateCardListActive: (state, status) => {
    state.cardListActive = status
  },
  //Asigna los valores de la tabla
  setDataTable: (state, dataTable) => {
    state.dataTable = dataTable
  },
  //Asigna los valores al objeto
  setDataOperationActive: (state, dataOperationActive) => {
    state.dataOperationActive = dataOperationActive
  },
  //Asigna los valores al objeto
  setDataCloneUpdateDataOperationActive: (state, dataCloneOperationActive) => {
    state.cloneDataOperationActive = _.cloneDeep(dataCloneOperationActive)
  },
}
