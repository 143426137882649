const BasesView = () => import('@/modules/bases/views/BasesView')
const CreateBases = () => import('@/modules/bases/views/CreateBases')
const UpdateBases = () => import('@/modules/bases/views/UpdateBases')

export default [
  {
    path: '/bases',
    name: 'bases',
    meta: {
      title: 'Bases',
      rule: 'manager',
    },
    component: BasesView,
  },
  {
    path: '/nueva-base',
    name: 'newBases',
    meta: {
      title: 'Crear base',
      rule: 'manager',
    },
    component: CreateBases,
  },
  {
    path: '/editar-base',
    name: 'UpdateBases',
    meta: {
      title: 'Editar base',
      rule: 'manager',
    },
    component: UpdateBases,
  },
]
