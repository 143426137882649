import axios from 'axios'
/**
 * Recupera los datos de la tabla de operaciones
 */
// eslint-disable-next-line no-unused-vars
export async function getDataTableOperation({ commit }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/operations/operation/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
/**
 * Inserta una nueva operación
 */
export async function newItemDataTableOperation(store, { type, data }) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/operations/${type}/`, data)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
/**
 * Cambia el status isActive de la operación
 */
export async function updateIsActiveOperationActive(store, { type, data }) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/operations/${type}/${data.id}/`, data)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updateDataOperationActive(store, { type, data }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/operations/${type}/${data.id}/`, data)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// export async function getDataOperationFilterByName({ commit }, params = null) {
//   console.log('FilterByName')
//   // return new Promise((resolve, reject) => {
//   //   let url = '/operations/operation_detail_sequence/'
//   //   url = params !== null ? `${url}?${params}` : url
//   //   axios
//   //     .get(url)
//   //     .then((response) => {
//   //       resolve(response.data)
//   //     })
//   //     .catch((error) => {
//   //       reject(error)
//   //     })
//   // })
// }
