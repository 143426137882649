const LoginView = () =>
  import(/* webpackChunkName: "auth" */ '@/modules/auth/views/LoginView.vue')
const RegisterView = () =>
  import(/* webpackChunkName: "auth" */ '@/modules/auth/views/RegisterView.vue')
const RestorePasswordEmailView = () =>
  import(
    /* webpackChunkName: "auth" */ '@/modules/auth/views/RestorePasswordEmailView'
  )
const RestorePasswordView = () =>
  import(
    /* webpackChunkName: "auth" */ '@/modules/auth/views/RestorePasswordView.vue'
  )
const NewPasswordView = () =>
  import(
    /* webpackChunkName: "auth" */ '@/modules/auth/views/NewPasswordView.vue'
  )
const HomeView = () =>
  import(/* webpackChunkName: "auth" */ '@/modules/auth/views/HomeView.vue')

export default [
  {
    path: '/auth/login',
    name: 'authLogin',
    meta: {
      title: 'Iniciar Sesión',
      rule: 'public',
    },
    component: LoginView,
  },
  {
    path: '/auth/signup',
    name: 'authRegister',
    meta: {
      title: 'Registro',
      rule: 'public',
    },
    component: RegisterView,
  },
  {
    path: '/password-recovery',
    name: 'passwordRecovery',
    meta: {
      title: 'Recuperar contraseña',
      rule: 'public',
    },
    component: RestorePasswordEmailView,
  },
  {
    path: '/password_reset',
    name: 'passwordReset',
    meta: {
      title: 'Coloque sus nueva contraseña',
      rule: 'public',
    },
    component: RestorePasswordView,
  },
  {
    path: '/password_new_user',
    name: 'passwordNewUser',
    meta: {
      title: 'Coloque su contraseña',
      rule: 'public',
    },
    component: NewPasswordView,
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: 'Bienvenido',
      rule: 'manager',
    },
    component: HomeView,
  },
]
