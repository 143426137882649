import axios from 'axios'

// eslint-disable-next-line no-unused-vars
export async function newItemCatalog(store, { type, data }) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/catalogs/${type}/`, data)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updateMachineCatalog(store, { type, data }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/catalogs/${type}/${data.id}/`, data)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updateDeviceCatalog(store, { type, data }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/catalogs/${type}/${data.id}/`, data)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getMachineCatalog({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/catalogs/machine/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getDeviceCatalog({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/catalogs/device/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
