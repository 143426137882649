<template>
  <div id="app">
    <transition name="slide-fade">
      <navbar v-if="isLogged && showNavbar" />
    </transition>
    <transition name="slide-fade">
      <main-sidebar v-if="isLogged" />
    </transition>
    <main class="full-height">
      <transition name="slide-fade">
        <router-view />
      </transition>
    </main>
    <transition name="slide-fade">
      <app-footer />
    </transition>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'
import AppFooter from '@/components/Footer'
import MainSideBar from '@/components/MainSideBar'

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'App',
  components: {
    navbar: NavBar,
    'main-sidebar': MainSideBar,
    'app-footer': AppFooter,
  },
  computed: {
    ...mapState(['activeLoading', 'loadingPageMsg', 'showNavbar']),
    ...mapGetters('auth', { isLogged: 'isLogged' }),
    ...mapState('auth', { user: 'user' }),
  },
  watch: {
    activeLoading: function (isLoading) {
      // Don't use Arrow Functions for this case
      if (isLoading) {
        this.$vs.loading({
          text: this.loadingPageMsg,
        })
      } else {
        this.$vs.loading.close()
      }
    },
  },
  created() {
    if (this.isLogged) {
      this.getUser(this.user.username).then((response) => {
        const user = {
          id: response.id,
          username: response.username,
          name: response.name,
          lastName: response.lastName,
          secondLastName: response.secondLastName,
          email: response.email,
          phoneNumber: response.phoneNumber,
          userprofile: {
            role: response.userprofile.role,
            picture: '#',
            company: {
              id: response.userprofile.company.id,
              name: response.userprofile.company.name,
              picture: response.userprofile.company.picture,
              email: response.userprofile.company.email,
            },
          },
        }
        this.setUser(user)
        this.getCompany(user.userprofile.company.id).then((response) => {
          this.setCompany(response)
        })
      })
    }
  },
  methods: {
    ...mapActions('users', {
      getUser: 'getUser',
    }),
    ...mapMutations('auth', {
      setUser: 'setUser',
    }),
    ...mapMutations('companies', {
      setCompany: 'setCompany',
      setSite: 'setSite',
    }),
    ...mapActions('companies', {
      getCompany: 'getCompany',
    }),
  },
}
</script>
<style>
.full-height {
  min-height: calc(100vh -110px) !important;
}
</style>
