import axios from 'axios'
import jwtDecode from 'jwt-decode'

export async function getToken({ commit }, user) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Iniciando sesión',
    },
    { root: true }
  )

  return new Promise((resolve, reject) => {
    axios
      .post('/auth/token/', user)
      .then((response) => {
        const data = response['data']
        const tokenAccess = data['access']
        const tokenRefresh = data['refresh']
        const decoded = jwtDecode(tokenAccess)
        let url = `${axios.defaults.baseURL}users/${decoded['username']}/`
        fetch(url, {
          headers: {
            Authorization: 'Bearer ' + tokenAccess,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.user.userprofile.role === 'client') {
              let error = {
                message: 'Los clientes no pueden iniciar sesión',
              }
              reject(error)
            } else {
              commit('setUserTokens', {
                tokenAccess,
                tokenRefresh,
              })
              resolve(response)
            }
          })
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

export async function refreshToken({ state }) {
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/token/refresh/', { refresh: state.tokenRefresh })
      .then((response) => {
        state.tokenAccess = response.data.access

        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line object-curly-newline
export async function logout({ commit }) {
  commit('logout')
}

export async function singUp({ commit }, data) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Registrando usuario',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/signup/', data)
      .then((response) => {
        resolve(response !== undefined)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

export async function updatePassword({ commit }, data) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando contraseña',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .put('/auth/update_password/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Permite enviar un correo para recuperar la cuenta.
 *
 * @param {String} data.email Email del que se requiere recuperar la contraseña
 */
// eslint-disable-next-line no-unused-vars
export async function recoverPasswordByEmail({ commit }, data) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Enviando email de recuperación',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/recover-password-email/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Resetea las credenciales de la cuenta.
 *
 * @param {String} data.password Nueva contraseña
 * @param {String} data.passwordConfirmation Confirmación de la nueva contraseña
 * @param {String} data.token JWT Token
 */
// eslint-disable-next-line no-unused-vars
export async function recoveryPassword({ commit }, data) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Cambiando credenciales',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/recover-password/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * crea las credenciales para una nueva cuenta.
 *
 * @param {String} data.password Nueva contraseña
 * @param {String} data.passwordConfirmation Confirmación de la nueva contraseña
 * @param {String} data.token JWT Token
 */
// eslint-disable-next-line no-unused-vars
export async function newManagerPassword({ commit }, data) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Creando credenciales',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/new-manager-password/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

export async function userActivation({ commit }, token) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Activando cuenta',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/verify/', { token })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}
