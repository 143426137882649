export let baseUser = {
  id: null,
  username: '',
  name: '',
  lastName: '',
  secondLastName: '',
  email: '',
  phoneNumber: '',
  userprofile: {
    role: '',
    picture: '',
    company: {
      id: null,
      name: '',
      picture: '',
      email: '',
    },
  },
}
