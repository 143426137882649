const CatalogsView = () =>
  import(
    /* webpackChunkName: "catalogs" */ '@/modules/catalogs/views/CatalogsView'
  )

export default [
  {
    path: '/catalogos',
    name: 'catalogs',
    meta: {
      title: 'Catálogos',
      rule: 'manager',
    },
    component: CatalogsView,
  },
]
