export default {
  users: [],
  emailError: false,
  company: {
    id: null,
    name: '',
    picture: '#',
    email: '',
    phone: '',
  },
}
