import axios from 'axios'

/**
 * Inserta una nueva base
 */
// eslint-disable-next-line no-unused-vars
export async function newBase(store, { data }) {
  return new Promise((resolve, reject) => {
    let url = `/bases_prueba/base_detail/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Inserta una imagen en la base que se inserta
 */
// eslint-disable-next-line no-unused-vars
export async function newBaseImage({ commit }, { formData, id }) {
  return new Promise((resolve, reject) => {
    let url = `/bases_prueba/base_detail/${id}/`
    axios
      .patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Recupera los datos de la tabla de bases
 */
// eslint-disable-next-line no-unused-vars
export async function getDataTableBase({ commit }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/bases_prueba/base_detail/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// editar
// eslint-disable-next-line no-unused-vars
export async function updateDataBaseBack(store, { data, id }) {
  return new Promise((resolve, reject) => {
    let url = `/bases_prueba/base_detail/${id}/`
    axios
      .put(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
