import axios from 'axios'
/**
 * Recupera los datos de la tabla de detalles
 */
// eslint-disable-next-line no-unused-vars
export async function getDataTableDetail({ commit }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/operations/operation_detail/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
// eslint-disable-next-line no-unused-vars
export async function getDataOperationDetailFilter({ commit }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/operations/operation/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
/**
 * Inserta un nuevo detalle
 */
// eslint-disable-next-line no-unused-vars
export async function newDetailMainFeature(store, { data }) {
  return new Promise((resolve, reject) => {
    let url = `/operations/operation_detail/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
/**
 * Inserta una imagen en el detalle que se inserta
 */
// eslint-disable-next-line no-unused-vars
export async function newDetailImage({ commit }, { formData, id }) {
  return new Promise((resolve, reject) => {
    let url = `/operations/operation_detail/${id}/`
    axios
      .patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getOperationOfDetail({ commit }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/operations/operation_detail_sequence/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
/**
 * Elimina un detalle
 */
// eslint-disable-next-line no-unused-vars
export async function updateIsActiveDetailActive(store, { data, id }) {
  return new Promise((resolve, reject) => {
    let url = `/operations/operation_detail/${id}/`
    axios
      .patch(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updateDataDetailBack(store, { data, id }) {
  return new Promise((resolve, reject) => {
    let url = `/operations/operation_detail/${id}/`
    axios
      .put(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
